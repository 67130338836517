/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/*
TEMPLATE DESIGN
https://preview.themeforest.net/item/oneuiux-creative-multipurpose-mobile-html-template/full_screen_preview/26757994?_ga=2.39837665.355611312.1613634813-1003667400.1600664322
*/

.ngOtpIp .Otp-ip-custom-class.otp-input {
  border: 1px solid #777;
  font-size: 14px;
  color: #231F20;
  background-color: #fff;
}

.ngOtpIp .Otp-ip-custom-class.otp-input {
  max-width: 40px;
  max-height: 40px;
}

.ngOtpIp .Otp-ip-custom-class.otp-input:focus-visible {
  outline: none;
}

.ngOtpIp.inValid .otp-input {
  border-color: #dc3545;
}

// .shareViaFormModal {
//   align-items: flex-end;
// }

// .shareViaFormModal .modal-wrapper.sc-ion-modal-md, .shareViaFormModal .modal-shadow.sc-ion-modal-md {
// height: 240px;
// }

.shareViaFormModal {
  --height: auto;
  align-items: flex-end;
  .ion-page {
      position: relative;
      contain: content;
      width: 100%;
      max-height: 100vh;
      .modal-content {
          overflow: auto;
      }
  }
}

.shareViaFormModal .modal-wrapper.sc-ion-modal-md,
.modal-shadow.sc-ion-modal-md {
  display: flex;
}

/* Number Pad */

body .keypad-btns button {
  border: none;
  font-size: 1.5rem;
}

body .keypad-container[class="keypad-container"] {
  height: unset;
}

body .keypad[class="keypad"] {
  width: 275px;
  border: none;
}

body .keypad-keys[class="keypad-keys"] {
  border-top: none;
}

body .keypad-screen[class="keypad-screen"] input[type="text"] {
  font-size: 2rem;
  text-align: center;
  height: 45px;
}

// body .keypad-screen[class="keypad-screen"] input[type="text"]::before {
//   content: "M";
// }

.keypad-screen, .numericPad .keypad-keys[class="keypad-keys"] .op-btn[class="op-btn"] {
  display: none;
}

.keypad-keys .keypad-btns button {
  height: unset;
  border-radius: 50%;
  background: white;
  // box-shadow: inset 3px -3px 6px #000000, inset -3px 3px 6px #000000;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}

.keypad-btns button:focus {
  outline: none;
}

.keypad-btns button:nth-child(1) {
order: 7;
}

.keypad-btns button:nth-child(2) {
  order: 8;
}

.keypad-btns button:nth-child(3) {
  order: 9;
}

.keypad-btns button:nth-child(4) {
order: 4;
}

.keypad-btns button:nth-child(5) {
  order: 5;
}

.keypad-btns button:nth-child(6) {
  order: 6;

}

.keypad-btns button:nth-child(7) {
  order: -3;
}

.keypad-btns button:nth-child(8) {
  order: -2;
}

.keypad-btns button:nth-child(9) {
  order: -1;
}
.keypad-btns button:nth-child(10) {
  order: 10;
}
.keypad-btns button:nth-child(11) {
  order: 11;
}
.keypad-btns button:nth-child(12) {
  order: 12;
  }
ngx-numaric-keypad.numericPad[class="numericPad"] .keypad-keys .keypad-btns button[type="button"] {
  aspect-ratio: 1 / 1;
  flex: 0 0 60px ;
}


/* // Number Pad */
